import React, { Fragment } from "react";
import PageTitle from "../../components/utility";
import { LayoutTwo } from "../../layouts";
import { PrivacyPolicy } from "../../components/PrivacyPolicy";

const PrivacyPolicyPage = () => {
  return (
    <Fragment>
      <PageTitle
        PageTitle="GameChronos - Privacy policy"
        favicon="assets/images/gamechronos/favourite-icon-5.png"
      />
      <LayoutTwo
        activeClass="active"
        wrapperClass="home-page-5"
        sectionSpace="sec-ptb-160 pb-0"
        whitekContent="white-content"
        logoWhite="assets/images/gamechronos/logo-1-black.png"
      >
        <main>
          <PrivacyPolicy sectionSpace="sec-ptb-160" />
        </main>
      </LayoutTwo>
    </Fragment>
  );
};

export default PrivacyPolicyPage;
