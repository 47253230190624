import React, { Fragment } from "react";
import PageTitle from "../../components/utility";
import { LayoutTwo } from "../../layouts";
import { BannerFive } from "../../components/Banner";
import { FeatureFoure } from "../../components/Features";
const HomePageFive = () => {
  return (
    <Fragment>
      <PageTitle
        PageTitle="GameChronos - Master Your Game"
        favicon="assets/images/gamechronos/favourite-icon-5.png"
      />
      <LayoutTwo
        activeClass="active"
        wrapperClass="home-page-5"
        sectionSpace="sec-ptb-160 pb-0"
        whitekContent="white-content"
        logoWhite="assets/images/gamechronos/logo-1.png"
      >
        <main>
          <BannerFive />
          <FeatureFoure />
        </main>
      </LayoutTwo>
    </Fragment>
  );
};

export default HomePageFive;
